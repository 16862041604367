import moment from 'moment';
import xmlFormat from 'xml-formatter';
import momentZone from 'moment-timezone';
import jsonFormatter from 'json-string-formatter';
import _ from 'lodash';
import { store } from '../redux/store/store';
import {
  CST_TIMEZONE_LOCATION,
  // IST_TIMEZONE_LOCATION,
  dateTimeFormat
} from '../constants/commonConstants';
import {
  EXCEPTION_TABLE_KEY,
  LOG_TABLE_KEY,
  USER_ADMIN_ACCESS_KEY
} from '../constants/KeyLabels/commonKeyConstants';
import { successNotification } from '../components/common/Notification';
import { type labelValueOptionType } from '../constants/Interfaces/FormFieldTypes';
import { snowServiceNameOptions } from '../constants/DropDownOptions/adminConfig';
import { lineBorderColorList } from '../constants/DropDownOptions/dashboard';

type ObjDifferenceResult = {
  oldValue: string;
  newValue: string;
};

export const findIfContainsInArrayUniversalSearch = (
  arrayToFindIn: any[],
  searchField: any
): any => {
  if (typeof searchField === 'object') {
    return arrayToFindIn?.filter((objectInArray: any) => {
      return searchField.every((searchFieldItem: string) =>
        JSON.stringify(objectInArray).toLowerCase().includes(searchFieldItem.toLowerCase())
      );
    });
  } else if (typeof searchField === 'string') {
    return arrayToFindIn?.filter((objectInArray: any) =>
      JSON.stringify(objectInArray).toLowerCase().includes(searchField.toLowerCase())
    );
  }
};

export const checkIfDataIsXML = (xmlStr: any): boolean => {
  const domParser = new DOMParser();
  const dom = domParser.parseFromString(xmlStr, 'text/xml');
  if (dom.getElementsByTagName('parsererror').length > 0) {
    return false;
  }
  return true;
};
export const checkIfDataIsJSON = (string: any): boolean => {
  if (typeof string !== 'string') {
    return false;
  }
  try {
    const jsonData = JSON.parse(string);
    if (typeof jsonData === 'object' && !Array.isArray(jsonData)) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const copyDataToClipBoard = (dataToBeCopied: any): void => {
  if (checkIfDataIsXML(dataToBeCopied)) {
    void navigator.clipboard.writeText(xmlFormat(dataToBeCopied));
  } else if (checkIfDataIsJSON(dataToBeCopied)) {
    void navigator.clipboard.writeText(jsonFormatter.format(dataToBeCopied));
  } else {
    void navigator.clipboard.writeText(dataToBeCopied);
  }
  successNotification('Data Copied!');
};

export const checkifAnyObjectPropertyIsTruthy = (dataObj: any): any => {
  for (const property in dataObj) {
    if (Object.values(dataObj[property].items).some((val) => val === true)) return true;
  }
};

export const formatBytes = (bytes: number, decimals: number = 2): string => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const checkIfAllObjectPropertyTruthy = (obj: any): boolean => {
  return Object.values(obj).every((item) => item === true);
};

export const checkIfAllObjectPropertyFalsy = (obj: any): boolean => {
  return Object.values(obj).every((item) => item === false);
};

export const checkIfAllColumnItemSelected = (filterObj: any, category: string): boolean => {
  return Object.values(filterObj[category].items).every((val) => val === true);
};

export const convertTimeToTimeZone = (dateTime: any): any => {
  if (typeof dateTime === 'string') {
    dateTime = moment(dateTime);
  }
  if (store.getState()?.commonConfig?.isCstTimeZone) {
    return momentZone
      .tz(dateTime.format(dateTimeFormat).toString(), CST_TIMEZONE_LOCATION)
      .format();
  } else {
    return momentZone.tz(dateTime.format(dateTimeFormat).toString(), moment.tz.guess()).format();
  }
};

export const checkIfSelectedApplicationHaveSameTableName = (
  applicationList: any[],
  logExceptionSearchType: string | undefined
): boolean => {
  const tableType =
    logExceptionSearchType?.toString().toLowerCase() === 'log'
      ? LOG_TABLE_KEY
      : EXCEPTION_TABLE_KEY;
  const isTableNameSame = true;
  if (applicationList.length > 0) {
    const applicationTableName = applicationList[0][tableType];

    for (let i = 0; i < applicationList.length; i++) {
      const applicationObject = applicationList[i];
      if (applicationObject[tableType] !== applicationTableName) {
        return false;
      }
    }
  }
  return isTableNameSame;
};

export const checkIfExistsInArray = (
  originalArray: any[],
  itemToFind: any,
  key: string | any[],
  ignoreKey?: string,
  ignoreId?: string
): any => {
  if (typeof key === 'string') {
    return originalArray?.some(
      (objectInArray) =>
        objectInArray[key]?.toString().toLowerCase() ===
          itemToFind[key]?.toString().toLowerCase() &&
        (ignoreKey && ignoreId ? objectInArray[ignoreKey] !== ignoreId : true)
    );
  } else if (typeof key === 'object') {
    return originalArray?.some((objectInArray) => {
      return (
        key.every(
          (listItem) =>
            objectInArray[listItem]?.toString().toLowerCase() ===
            itemToFind[listItem]?.toString().toLowerCase()
        ) && (ignoreKey && ignoreId ? objectInArray[ignoreKey] !== ignoreId : true)
      );
    });
  }
};

export const findIfContainsInArray = (
  originalArray: any[],
  arrayToCheck: any[],
  key: any[]
): any => {
  return originalArray.filter((objectInArray) => {
    return arrayToCheck.some((ObjectToCheck) => {
      return key.every((listItem) => objectInArray[listItem] === ObjectToCheck[listItem]);
    });
  });
};

export const convertTimeToTimeZoneForDisplay = (dateTime: any): any => {
  if (typeof dateTime === 'string') {
    dateTime = moment(dateTime);
  }

  if (!dateTime) {
    return '';
  }

  if (store.getState()?.commonConfig?.isCstTimeZone) {
    return momentZone(dateTime).tz(CST_TIMEZONE_LOCATION).format(dateTimeFormat).toString();
  } else {
    return moment(dateTime).local().format(dateTimeFormat).toString();
  }
};

export const getUserStatus = (lastLogin: any): string => {
  let status = 'Inactive';

  if (lastLogin) {
    const currentDate = moment();
    const dayDifference = currentDate.diff(moment(lastLogin), 'days');

    status = dayDifference <= 90 ? 'Active' : 'Inactive';
  }

  return status;
};

export const getTimeStamp = (time: string): string => {
  if (store.getState()?.commonConfig?.isCstTimeZone) {
    return momentZone(time).tz(CST_TIMEZONE_LOCATION).format('YYYY-MM-DDTHH:mm:ss.SSS+0000');
  } else {
    return moment(time).local().format('YYYY-MM-DDTHH:mm:ss.SSS+0000');
  }
};

export const checkIfUserHasAccess = (applicationName: string, accessName: string): boolean => {
  const userInfo = store.getState()?.userAccess?.userAccessInfo;
  if (userInfo[USER_ADMIN_ACCESS_KEY] === 'Y') {
    return true;
  }
  const roleAccessObject = userInfo?.roleAccess;
  if (applicationName && roleAccessObject && Object.hasOwn(roleAccessObject, applicationName)) {
    // need to revert once data in db is corrected as for now in db access names are : edit_JMS_Header instead of edit
    // return roleAccessObject?.[applicationName].includes(accessName);
    return roleAccessObject?.[applicationName].some((access: string) =>
      access.includes(accessName)
    );
  }
  return false;
};

export const getLoggedInUserData = (userProperty?: string | null): any => {
  const loggedInUser = store.getState()?.userAccess?.userAccessInfo;
  if (userProperty && loggedInUser?.[userProperty]) {
    return loggedInUser?.[userProperty];
  } else {
    return loggedInUser;
  }
};

export const getItemFromArrayOfObject = (orgArray: any[], itemKey: string): any => {
  return orgArray.map((itemInArray) => itemInArray[itemKey]).filter((ele) => ele !== undefined);
};

export const generateOptionsFromList = (orgArray: any[], itemKey: string): any => {
  return orgArray?.map((itemInArray) => ({
    label: itemInArray[itemKey],
    value: itemInArray[itemKey],
    ...itemInArray
  }));
};

export const generateTableMetaDataOptionsFromList = (orgArray: any[]): any => {
  return orgArray?.map((itemInArray) => ({
    ...itemInArray,
    table: itemInArray.dataType,
    topic: itemInArray.topicName,
    label: itemInArray.tableName,
    value: itemInArray.tableName
  }));
};

export const findLabelByValue = (
  orgArray: labelValueOptionType[],
  selectedValue: string
): string => {
  for (let i = 0; i < orgArray.length; i++) {
    if (orgArray[i].value === selectedValue) {
      return orgArray[i].label;
    }
  }
  return 'N/A';
};

export const getSelectedDaysLabel = (dayObj: any): string => {
  let dayStr = '';
  const weekDay = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];

  if (dayObj && Object.keys(dayObj).length > 0) {
    for (const key of Object.keys(dayObj)) {
      if (dayObj[key]) {
        dayStr += weekDay[parseInt(key) - 1].concat(', ');
      }
    }
    dayStr = dayStr.slice(0, -2);
  }

  return dayStr;
};

export const getLabelValueFormatter = (value: any, type: string = 'text'): string => {
  if (type === 'radio') {
    return ['Yes', 'Y', 'y', 1, true].includes(value) ? 'Yes' : 'No';
  } else {
    return ((value?.toString() === '0' || value) && value.toString()) || 'N/A';
  }
};

export const checkIsValidValue = (value: any): boolean => {
  return (value === 0 || value) && value.toString().length > 0;
};

export const formatNumberForDisplay = (value?: number): string => {
  return new Intl.NumberFormat('en-US').format(value ?? 0);
};

export const createApplicationAccessData = (accessData: any): any[] => {
  if (accessData !== null && Object.keys(accessData)?.length > 0) {
    return Object.keys(accessData)?.map((applicationName: string) => ({
      applicationName,
      accessList: accessData[applicationName]
    }));
  } else {
    return [];
  }
};

export const mergeApplicationAccess = (arr1: any, arr2: any): any[] => {
  const mergedMap = new Map();

  // Merge objects from both arrays
  const mergeObjects = (objArray: any): void => {
    for (const obj of objArray) {
      const { applicationName, accessList } = obj;
      if (!mergedMap.has(applicationName)) {
        mergedMap.set(applicationName, { applicationName, accessList: [...accessList] });
      } else {
        const existingObj = mergedMap.get(applicationName);
        existingObj.accessList.push(...accessList);
        existingObj.accessList = _.union(existingObj.accessList);
      }
    }
  };

  mergeObjects(arr1 ?? []);
  mergeObjects(arr2 ?? []);

  // Convert the map values back to an array
  const mergedArray = Array.from(mergedMap.values());

  return mergedArray;
};

export const getDataFromSessionStorage = (keyName: string): any => {
  return JSON.parse(sessionStorage.getItem(keyName) ?? '[]');
};

export const getSnowServiceClassName = (selectedValue: string): string => {
  const selectedObj = snowServiceNameOptions.find((o) => o.value === selectedValue);
  return selectedObj ? selectedObj.label : '';
};

export const ignoreInvaildValueFromArray = (data: any): any[] => {
  return data.filter((item: any) => item !== null || item !== undefined);
};

export const filterListByField = (originalArray: any[], field: any): any[] => {
  if (field.name && field.data) {
    return originalArray?.filter((objectInArray) =>
      field.isExist
        ? field.data.includes(objectInArray?.[field.name]?.toString())
        : !field.data.includes(objectInArray?.[field.name]?.toString())
    );
  } else if (field.name && field.isExist && !field.data) {
    return [];
  }

  return originalArray;
};

export const getUserDataByIds = (allUserList: any[], idsToFind: string[]): any[] => {
  return allUserList?.filter((userObj) => idsToFind?.includes(userObj.userID));
};
export const getUsersNotInList = (allUserList: any[], idsToIgnore: string[]): any[] => {
  return allUserList?.filter((userObj) => !idsToIgnore?.includes(userObj.userID));
};

export const filterOptionListBySelectedValue = (
  fieldName: string,
  optionList: any[],
  selectedValues: any[]
): any[] => {
  return (
    optionList?.filter(
      (item) =>
        !selectedValues?.some(
          (ignoreElement) => ignoreElement && ignoreElement[fieldName] === item[fieldName]
        )
    ) ?? []
  );
};

// Method to generate random colors for chart
export const generateRandomColor = (itemIndex: number): string => {
  if (itemIndex <= 12) {
    return lineBorderColorList[itemIndex];
  } else {
    const lum = -0.25;
    let hex = String('#' + Math.random().toString(16).slice(2, 8).toUpperCase()).replace(
      /[^0-9a-f]/gi,
      ''
    );
    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    let rgb = '#';
    let c;
    let i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
      rgb += ('00' + c).substr(c.length);
    }
    return rgb;
  }
};

export const appendArrayWithKeyValue = (arr: any[], key: string, value: string): any[] => {
  return _.map(arr, (o) => _.extend({ [key]: value }, o));
};

export const disableFutureDateSelection = (
  current: any,
  isPastMonthDateEnabled: boolean = false
): boolean => {
  if (isPastMonthDateEnabled) {
    return current > moment();
  }

  return current > moment() || current < moment().subtract(1, 'month');
};

// Method to return difference between two objects (oldObj & newObj) for Update Data
export const getEditDataChanges = (
  oldObj: Record<string, any> | null,
  newObj: Record<string, any>,
  tableName?: string,
  ignoreKeyList?: String[]
): ObjDifferenceResult | null => {
  const oldValue: Record<string, any> = {};
  const newValue: Record<string, any> = {};
  if (!oldObj) {
    return {
      ...newObj,
      oldValue: '',
      newValue: JSON.stringify(newObj),
      ...(tableName && { tableName })
    };
  } else {
    for (const key in oldObj) {
      if (
        !_.isEqual(oldObj[key], newObj[key]) &&
        !ignoreKeyList?.includes(key) &&
        !(
          [undefined, null, ''].includes(oldObj[key]) && [undefined, null, ''].includes(newObj[key])
        )
      ) {
        if (_.isArray(oldObj[key]) && _.isArray(newObj[key])) {
          oldValue[key] = oldObj[key];
          newValue[key] = newObj[key];
        } else if (_.isObject(newObj[key]) && _.isObject(newObj[key])) {
          oldValue[key] = {};
          newValue[key] = {};
          for (const subKey in oldObj[key]) {
            if (!_.isEqual(oldObj[key][subKey], newObj[key][subKey])) {
              oldValue[key][subKey] = oldObj[key][subKey];
              newValue[key][subKey] = newObj[key][subKey];
            }
          }
        } else {
          oldValue[key] = oldObj[key];
          newValue[key] = newObj[key];
        }
      }
    }

    return Object.keys(oldValue).length > 0
      ? {
          ...newObj,
          oldValue: JSON.stringify(oldValue),
          newValue: JSON.stringify(newValue),
          ...(tableName && { tableName: tableName })
        }
      : null;
  }
};
