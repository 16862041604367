import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ConfigurationListTable from '../../../ConfigurationListTable';
import textConstants from '../../../../../constants/textConstants';
import AddEditConfigDrawer from '../../../Drawer/AddConfigDrawer';
import ColumnAlertConfigForm from '../../../UpsertForms/Admin/ColumnAlertConfig';
import routeConstants from '../../../../../constants/routeConstants';
import type { SelectedRows } from '../../../../../constants/Interfaces/AdminConfigListTypes';
import { checkIfExistsInArray, getEditDataChanges } from '../../../../../helpers/commonHelper';
import { columnAlertConfigItemKeys } from '../../../../../constants/KeyLabels/KeysToCheckForDuplicateItems';
import { COLUMN_ALERT_CONFIG_COLUMNS } from '../../../../../constants/TableColumns/Admin/ColumnAlertConfigColumns';
import { addColumnAlertConfig, deleteColumnAlertConfig, getColumnAlertConfigList, updateColumnAlertConfig } from '../../../../../redux/actions/Admin/columnAlertConfigActions';
import { errorNotification } from '../../../../common/Notification';
import { COLUMN_ALERT_ID, COLUMN_ALERT_KEY } from '../../../../../constants/KeyLabels/commonKeyConstants';
import { cloneDeep } from 'lodash';

interface ConfigListProps {
  isFromApplicationConfig?: boolean
  applicationName?: string
}

const ColumnAlertConfigList: FC<ConfigListProps> = ({ applicationName, isFromApplicationConfig }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const columnAlertConfigList = useSelector((state: any) => state?.columnAlertConfig?.columnAlertConfigList);
  const [isAddEditDrawerVisible, setAddEditDrawerVisibility] = useState(false);
  const [isMultipleModify, setMultipleModifyState] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<SelectedRows>([]);
  const [activeItemIndex, setActiveItemIndex] = useState<number>(0);

  const handleItemNavigation = (currentIndex: number): void => {
    setActiveItemIndex(currentIndex);
  }

  const onAddEditDrawerClose = (): void => {
    setAddEditDrawerVisibility(false);
    setMultipleModifyState(false);
    setActiveItemIndex(0);
  }

  const onCreateBtnClick = (): void => {
    setSelectedRows([]);
    setAddEditDrawerVisibility(true);
  }

  const onMultipleModify = (configList: SelectedRows): void => {
    setMultipleModifyState(true);
    setSelectedRows(configList);
    setAddEditDrawerVisibility(true);
  }

  const showDetailPageHandler = (rowData: any, isEditable: boolean): void => {
    history.push({
      pathname: routeConstants.ADMIN_COLUMN_ALERTS_DETAIL,
      state: { data: rowData, isEditable, isFromApplicationConfig }
    });
  }

  const saveConfigHandler = (configData: any, isClosable?: boolean): void => {
    const oldData = !isMultipleModify ? null : selectedRows[activeItemIndex]
    const updatedPayload = getEditDataChanges(oldData, configData, 'column_alert_config');
    if (!isMultipleModify && !checkIfExistsInArray(columnAlertConfigList, configData, columnAlertConfigItemKeys)) {
      dispatch(addColumnAlertConfig(updatedPayload, true));
    } else if (isMultipleModify) {
      dispatch(updateColumnAlertConfig(updatedPayload));
    } else {
      errorNotification(textConstants.COLUMN_ALERT_CONFIG_ALREADY_EXIST_MESSAGE);
    }
    if (isClosable) {
      setAddEditDrawerVisibility(false)
    }
  }

  useEffect(() => {
    if (isMultipleModify) {
      const configItem = selectedRows[activeItemIndex];

      const updatedConfigData = columnAlertConfigList.find((item: any) => {
        return ((item[COLUMN_ALERT_ID] === configItem[COLUMN_ALERT_ID]) && (item[COLUMN_ALERT_KEY] === configItem[COLUMN_ALERT_KEY]))
      });

      const clonedSelectedRows = cloneDeep(selectedRows);
      clonedSelectedRows[activeItemIndex] = updatedConfigData;
      setSelectedRows(clonedSelectedRows);
    }
  }, [columnAlertConfigList, isMultipleModify]);

  return (
    <>
      <ConfigurationListTable
        columnDefs={COLUMN_ALERT_CONFIG_COLUMNS}
        gridItemId={COLUMN_ALERT_ID}
        gridName={textConstants.COLUMN_ALERT_CONFIG_TITLE}
        getConfigList={getColumnAlertConfigList}
        onConfigDelete={deleteColumnAlertConfig}
        onMultipleModify={onMultipleModify}
        listName='columnAlertConfig'
        hasFilter={!isFromApplicationConfig}
        rowSelection="multiple"
        onCreate={onCreateBtnClick}
        onRowDoubleClicked={(params: { data: any }) => showDetailPageHandler(params?.data, false)}
        onEdit={(data: any) => showDetailPageHandler(data, true)}
        filterDataByApplicationName={applicationName}
        hasAuditSummary
        hasCreate
        hasRowAction
        hideRowCheckbox
        rowMultiSelectWithClick
      />
      {
        isAddEditDrawerVisible && (
          <AddEditConfigDrawer
            isOpen={isAddEditDrawerVisible}
            dataTestId='test-id-column-alert-add-drawer'
            title={`${isMultipleModify ? 'Modify' : 'New'} ${textConstants.COLUMN_ALERT_CONFIG_ADD_EDIT_DRAWER_TITLE}`}
            onCancel={onAddEditDrawerClose}
            handleItemNavigation={handleItemNavigation}
            currentItemIndex={activeItemIndex}
            selectedItemsCount={selectedRows.length - 1}
          >
            <ColumnAlertConfigForm
              data={{
                applicationName,
                ...(isMultipleModify && { ...selectedRows[activeItemIndex] })
              }}
              onSave={saveConfigHandler}
              onCancel={onAddEditDrawerClose}
              isEditable={isMultipleModify}
              isDrawerView
            />
          </AddEditConfigDrawer>
        )
      }
    </>
  );
};

ColumnAlertConfigList.defaultProps = {
  isFromApplicationConfig: false
}

ColumnAlertConfigList.propTypes = {
  applicationName: PropTypes.string,
  isFromApplicationConfig: PropTypes.bool
}

export default ColumnAlertConfigList;
