import React, { useRef } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-dropdown-select';
import type { SelectProps, SelectState, SelectMethods } from 'react-dropdown-select';
import { Button, Checkbox, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import {
  MutliSelectButtonWrapper,
  MultiSelectCheckboxWrapper,
  MultiSelectHeaderInfo,
  MultiSelectInnerContainer,
  MultiSelectTag,
  MultiSelectTagLabel,
  MultiSelectTagClear,
  MultiSelectHeaderWrapper,
  MultiSelectWrapper,
  MultiSelectOptionListWrapper,
  MultiSelectOptionHeaderPanel,
  MultiSelectOptionItem,
  SelectedTagListWrapper
} from './style';
import textConstants from '../../../constants/textConstants';

interface MultiSelectInputProps {
  allowClear?: boolean
  fieldName?: string
  className?: string
  clearAllHandler?: any
  options: any
  value?: any
  disabled?: boolean
  disableSearch?: boolean
  hasSelectAll?: boolean
  isDefaultAllItemSelected?: boolean
  placeholder?: string
  renderAllOptionLabel?: string
  searchInputPlaceholder?: string
  [otherProps: string]: any
  dropdownPanelSize?: string
  fieldCustomIcon?: any
  showDoneButton?: boolean
  visibleTagCount?: number
  showSelectedTags?: boolean
  portal?: any
}

const MultiSelect: FC<MultiSelectInputProps> = ({
  allowClear,
  className,
  clearAllHandler,
  disabled,
  dropdownPanelSize,
  fieldName,
  fieldCustomIcon,
  options,
  value,
  disableSearch,
  hasSelectAll,
  isDefaultAllItemSelected,
  portal,
  placeholder,
  renderAllOptionLabel,
  searchInputPlaceholder,
  showDoneButton,
  showSelectedTags,
  visibleTagCount,
  ...otherProps
}) => {
  const multiSelectRef = useRef<HTMLInputElement>(null);

  const contentRenderer = (
    props: SelectProps<any>,
    state: SelectState<any>,
    methods: SelectMethods<any>
  ): JSX.Element => {
    if (!state.dropdown && state.values?.length > 0) {
      const tagContent: any = (isDefaultAllItemSelected && state.values?.length === options?.length) ? ['All Application'] : [renderTags(state.values, methods, visibleTagCount)];
      if ((isDefaultAllItemSelected && (state.values?.length !== options?.length) && (state.values?.length > (visibleTagCount ?? 2))) || (!isDefaultAllItemSelected && state.values?.length > (visibleTagCount ?? 2))) {
        tagContent.push(<MultiSelectTag className='tag-count'>+{state.values?.length - (visibleTagCount ?? 2)}</MultiSelectTag>);
      }
      return tagContent;
    }

    if (state.dropdown) {
      return (
        <Input
          value={state.search}
          onChange={methods.setSearch}
          placeholder={searchInputPlaceholder}
          bordered={false}
          autoFocus
        />
      )
    }

    return <div>{placeholder}</div>;
  };

  const noDataRenderer = (): JSX.Element => {
    return (
      <p>{textConstants.MULTISELECT_SEARCH_NO_DATA_FOUND}</p>
    );
  };

  const renderTags = (items: any, methods: SelectMethods<any>, maxTags?: number): JSX.Element => {
    return items?.slice(0, maxTags ?? items.length).map((item: any) => (
      <MultiSelectTag key={item?.label} isSingleSelection={!otherProps.multi}>
        <MultiSelectTagLabel>
          {item?.label}
        </MultiSelectTagLabel>
        <MultiSelectTagClear
          onClick={() => methods.addItem(item)}
        >
          x
        </MultiSelectTagClear>
      </MultiSelectTag>
    ));
  }

  const dropdownRenderer = (
    props: SelectProps<any>,
    state: SelectState<any>,
    methods: SelectMethods<any>,
    close: () => void
  ): JSX.Element => {
    return (
      <div>
        {!disableSearch && (
          <div>
            {hasSelectAll && (
              <>
                <MultiSelectHeaderWrapper isSelectedTagsVisible={showSelectedTags}>
                  {showSelectedTags && (
                    <>
                      <MultiSelectHeaderInfo>
                        {`${state?.values?.length > 0 && state?.values?.length === options.length ? 'All' : state?.values?.length} ${fieldName ?? 'Items'} Selected`}
                      </MultiSelectHeaderInfo>
                      {state?.values?.length > 0 && state?.values?.length !== options.length && (
                        <SelectedTagListWrapper>
                          {renderTags(state?.values, methods)}
                        </SelectedTagListWrapper>
                      )}
                    </>
                  )}
                </MultiSelectHeaderWrapper>
                <MultiSelectOptionHeaderPanel isSelectedTagsVisible={showSelectedTags}>
                  <MultiSelectCheckboxWrapper
                    {...(portal && {
                      onClick: () => methods?.areAllSelected() ? methods.clearAll() : methods.selectAll(),
                      'data-testid': 'selectAllWrapper',
                      isPointerNone: true
                    })}
                  >
                    <Checkbox
                      checked={methods?.areAllSelected()}
                      onChange={(evt) => {
                        evt.target.checked ? methods.selectAll() : methods.clearAll();
                      }}
                    >
                      {textConstants.MULTISELECT_SELECT_ALL_LABEL}
                    </Checkbox>
                  </MultiSelectCheckboxWrapper>
                </MultiSelectOptionHeaderPanel>
              </>
            )}
          </div>
        )}
        <MultiSelectInnerContainer>
          <MultiSelectOptionListWrapper dropdownPanelSize={dropdownPanelSize}>
            {props.options
              .filter((item: any) => item.label.toLowerCase().includes(state.search.toLowerCase()))
              .map((option: any) => {
                return (
                  <MultiSelectOptionItem
                    dropdownPanelSize={dropdownPanelSize}
                    key={option.label}
                    hasSingleSelection={!otherProps.multi}
                  >
                    <MultiSelectCheckboxWrapper
                      {...(portal && {
                        onClick: () => methods.addItem(option),
                        'data-testid': option.label,
                        isPointerNone: true
                      })}
                    >
                      <Checkbox
                        disabled={option.disabled}
                        onChange={() => methods.addItem(option)}
                        checked={methods.isSelected(option)}
                      >
                        {option.label}
                      </Checkbox>
                    </MultiSelectCheckboxWrapper>
                  </MultiSelectOptionItem>
                );
              })
            }
          </MultiSelectOptionListWrapper>
          {showDoneButton && (
            <MutliSelectButtonWrapper>
              <Button
                className='btn-regular'
                onClick={() => multiSelectRef?.current?.click()}
                type="primary"
              >
                {textConstants.MULTISELECT_DONE_BUTTON_LABEL}
              </Button>
            </MutliSelectButtonWrapper>
          )}
        </MultiSelectInnerContainer>
      </div>
    );
  };

  return (
    <MultiSelectWrapper dropdownPanelSize={dropdownPanelSize} className={disabled ? 'multiselect-disabled' : ''}>
      <Select
        className={className ?? ''}
        disabled={disabled}
        options={options}
        values={value}
        onChange={otherProps.onChange}
        dropdownHandleRenderer={() => fieldCustomIcon}
        contentRenderer={({ props, state, methods }) => contentRenderer(props, state, methods)}
        dropdownRenderer={({ props, state, methods }) => dropdownRenderer(props, state, methods, close)}
        noDataRenderer={noDataRenderer}
        dropdownPosition='auto'
        placeholder=''
        clearable={value?.length > 0 && allowClear}
        clearOnSelect={false}
        {...(portal && { portal })}
        {...otherProps}
        data-testid="multiSelect"
      />
      <span ref={multiSelectRef} />
    </MultiSelectWrapper>
  );
};

MultiSelect.defaultProps = {
  allowClear: true,
  isDefaultAllItemSelected: false,
  searchInputPlaceholder: textConstants.MULTISELECT_SEARCH_INPUT_PLACEHOLDER,
  renderAllOptionLabel: textConstants.MULTISELECT_RENDER_ALL_LABEL,
  disableSearch: false,
  fieldName: 'Items',
  hasSelectAll: true,
  dropdownPanelSize: 'default',
  fieldCustomIcon: <SearchOutlined />,
  showDoneButton: false,
  showSelectedTags: true,
  visibleTagCount: 2
};

MultiSelect.propTypes = {
  allowClear: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fieldName: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.array,
  disableSearch: PropTypes.bool,
  hasSelectAll: PropTypes.bool,
  isDefaultAllItemSelected: PropTypes.bool,
  portal: PropTypes.any,
  renderAllOptionLabel: PropTypes.string,
  placeholder: PropTypes.string,
  searchInputPlaceholder: PropTypes.string,
  clearAllHandler: PropTypes.func,
  dropdownPanelSize: PropTypes.string,
  fieldCustomIcon: PropTypes.any,
  showDoneButton: PropTypes.bool,
  showSelectedTags: PropTypes.bool,
  visibleTagCount: PropTypes.number
};

export default MultiSelect;
