import React, { useState, useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Button, Popover } from 'antd';
import { cloneDeep } from 'lodash';
import { EyeFilled, EyeInvisibleFilled, SearchOutlined } from '@ant-design/icons';
import textConstants from '../../../constants/textConstants';
import { ConfigColumnListWrapper, ConfigColumnPopoverWrapper, ConfigColumnSearchInput, ConfigColumnTitleWrapper } from './style';

interface ConfigureColumnPopoverProps {
  children?: ReactNode
  columnList: any[]
  initialColumnList: any[]
  isOpen: boolean
  mandatoryColumnList: string[]
  onColumnConfigApply: any
  handleOpenChange: any
}

const ConfigureColumnPopover: FC<ConfigureColumnPopoverProps> = ({
  children,
  columnList,
  initialColumnList,
  isOpen,
  mandatoryColumnList,
  onColumnConfigApply,
  handleOpenChange
}) => {
  const [isHideAll, setHideAllStatus] = useState(false);
  const [searchColumnValue, setSearchColumnValue] = useState<string>('');
  const [configColumnList, setConfigColumnList] = useState<any[]>([]);
  const [dragId, setDragId] = useState();

  const handleDrag = (evt: any): void => {
    setDragId(evt.currentTarget.id);
  };

  const handleDrop = (evt: any): void => {
    const dragBox = configColumnList.find((column) => column.field === dragId);
    const dropBox = configColumnList.find((column) => column.field === evt.currentTarget.id);

    const dragBoxOrder = dragBox.order;
    const dropBoxOrder = dropBox.order;

    const newBoxState = configColumnList.map((column) => {
      if (column.field === dragId) {
        column.order = dropBoxOrder;
      }
      if (column.field === evt.currentTarget.id) {
        column.order = dragBoxOrder;
      }
      return column;
    });

    setConfigColumnList(newBoxState);
  };

  /* Method to handle Search Column Input Change */
  const onSearchColumnChange = (evt: any): void => {
    setSearchColumnValue(evt.target.value);
  }

  const onReset = (): void => {
    const updatedColumnList = initialColumnList.filter((item: any) => {
      return item.headerName.trim() !== ''
    }).map((item: any, id: number) => ({
      ...item,
      order: id,
      isVisible: true
    }));
    setConfigColumnList(updatedColumnList);
    setSearchColumnValue('');
  }

  const onApply = (): void => {
    const updatedColumnList = configColumnList.sort((a, b) => a.order - b.order);
    onColumnConfigApply(updatedColumnList);
  }

  const onHideAllClickHandler = (isAllColVisible: boolean): void => {
    const updatedColumnList = configColumnList.map((item: any) => ({
      ...item,
      isVisible: !mandatoryColumnList.includes(item.field) ? isAllColVisible : true
    }));

    setConfigColumnList(updatedColumnList);
    setHideAllStatus(!isAllColVisible);
  }

  const toggleColumnVisibility = (fieldName: string, isVisible: boolean): void => {
    const clonedConfigColumnList = cloneDeep(configColumnList);
    const fieldIndex = configColumnList.findIndex((item: any) => item.field === fieldName);
    clonedConfigColumnList[fieldIndex].isVisible = !isVisible;
    setConfigColumnList(clonedConfigColumnList);
  }

  const renderFields = (): any => {
    return configColumnList.filter(item => item.headerName.toLowerCase().includes(searchColumnValue.toLowerCase())).sort((a, b) => a.order - b.order).map((item: any) => {
      return (
        <label
          key={item.headerName}
          id={item.field}
          draggable
          onDragOver={(evt) => evt.preventDefault()}
          onDragStart={handleDrag}
          onDrop={handleDrop}
        >
          {item.headerName}
          {!mandatoryColumnList.includes(item.field) && (
            <span data-testid={item.field} onClick={() => toggleColumnVisibility(item.field, item.isVisible)}>
              {item.isVisible ? <EyeFilled /> : <EyeInvisibleFilled />}
            </span>
          )}
        </label>
      );
    });
  };

  useEffect(() => {
    if (!isOpen) {
      setSearchColumnValue('');
      setConfigColumnList([]);
    } else {
      const updatedColumnList = columnList.filter((item: any) => {
        return item.headerName.trim() !== ''
      }).map((item: any, id: number) => ({
        ...item,
        order: item.order || id
      }));

      setConfigColumnList(updatedColumnList);
    }
  }, [isOpen]);

  useEffect(() => {
    const isAllColVisible = configColumnList.every(item => item.isVisible);

    setHideAllStatus(!isAllColVisible);
  }, [configColumnList]);

  return (
    <Popover
      arrow={false}
      open={isOpen}
      onOpenChange={handleOpenChange}
      placement="bottomLeft"
      trigger='click'
      content={
        <ConfigColumnPopoverWrapper>
          <ConfigColumnTitleWrapper>
            <h1>{textConstants.CONFIGURE_COLUMN_TITLE}</h1>
            <Button type='link' data-testid="hideAllBtn" onClick={() => onHideAllClickHandler(isHideAll)}>
              {isHideAll ? textConstants.CONFIGURE_COLUMN_SHOW_ALL_BUTTON_LABEL : textConstants.CONFIGURE_COLUMN_HIDE_ALL_BUTTON_LABEL}
            </Button>
          </ConfigColumnTitleWrapper>
          <ConfigColumnSearchInput
            value={searchColumnValue}
            addonBefore={<SearchOutlined />}
            placeholder='Search Column'
            onChange={onSearchColumnChange}
            allowClear
          />
          <ConfigColumnListWrapper>
            {renderFields()}
          </ConfigColumnListWrapper>
          <div className="text-right">
            <Button
              type="text"
              className="mr-10 btn-regular"
              onClick={onReset}
              data-testid="resetBtn"

            >
              {textConstants.RESET_BUTTON_LABEL}
            </Button>
            <Button
              className='btn-regular'
              type="primary"
              onClick={onApply}
              data-testid="configApplyBtn"
            >
              {textConstants.APPLY_BUTTON_LABEL}
            </Button>
          </div>
        </ConfigColumnPopoverWrapper>
      }
      rootClassName="configure-column-popover"
    >
      {children}
    </Popover>
  );
};

ConfigureColumnPopover.propTypes = {
  columnList: PropTypes.array.isRequired,
  children: PropTypes.any,
  initialColumnList: PropTypes.array.isRequired,
  handleOpenChange: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  mandatoryColumnList: PropTypes.array.isRequired,
  onColumnConfigApply: PropTypes.func.isRequired
};

export default ConfigureColumnPopover;
