import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Input, InputNumber, Select, DatePicker } from 'antd';
import MultiSelect from '../MultiSelect';
import TagInput from '../TagInput';
import { checkIsValidValue } from '../../../helpers/commonHelper';
import { Label, FloatingLabelWrapper } from './style';

interface FloatingLabelProps {
  type: string
  id?: string
  label: string | React.ReactNode
  value?: any
  options?: any
  required?: boolean
  [otherProps: string]: any
}

const FloatingLabel: FC<FloatingLabelProps> = ({ id, required, type, label, value, options, ...otherProps }) => {
  const [focus, setFocus] = useState(false);

  const renderField = (): JSX.Element => {
    switch (type) {
      case 'number':
        return <InputNumber id={id} value={value} {...otherProps} />;
      case 'textarea':
        return <Input.TextArea id={id} value={value} {...otherProps} />;
      case 'password':
        return <Input.Password id={id} value={value} {...otherProps} />;
      case 'select':
        return <Select id={id} value={value} options={options} allowClear {...otherProps} />;
      case 'tagInput':
        return <TagInput id={id} options={options} value={value} allowClear {...otherProps} />;
      case 'multiSelect':
        return <MultiSelect id={id} value={value} options={options} {...otherProps} />;
      case 'date':
        return <DatePicker id={id} placeholder='' {...otherProps} />;
      default:
        return <Input id={id} value={value} {...otherProps} />;
    }
  };

  return (
    <FloatingLabelWrapper data-testid="floatingLabel" onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
      {renderField()}
      <Label className={focus || checkIsValidValue(value) ? 'float-label' : ''}>
        {label} {required && <span>*</span>}
      </Label>
    </FloatingLabelWrapper>
  );
};

FloatingLabel.propTypes = {
  id: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  options: PropTypes.array,
  size: PropTypes.string
};

export default FloatingLabel;
