import React from 'react';
import type { FC } from 'react';
import TableRowActions from '../../../components/common/Table/TableRowActions';

interface RowActionButtonsProps {
  hasAuditSummary: boolean
  hasDelete?: boolean
  hasEdit?: boolean
  value?: any
  data?: any
  gridName?: string
  gridRowId: string
  isDeleteDisabled?: boolean
  isEditDisabled?: boolean
  onAuditSummary: any
  onDelete?: any
  onEdit?: any
}

const RowActionButtonsRenderer: FC<RowActionButtonsProps> = ({
  data,
  gridName,
  gridRowId,
  hasAuditSummary,
  hasDelete,
  hasEdit,
  isDeleteDisabled,
  isEditDisabled,
  onAuditSummary,
  onDelete,
  onEdit,
  value
}) => {
  return (
    <TableRowActions
      tableGridName={gridName}
      {...(hasAuditSummary && {
        hasAuditSummary,
        auditBtnId: data[gridRowId]?.toString(),
        onAuditSummary: () => onAuditSummary ? onAuditSummary(data) : undefined,
      })}
      {...(hasDelete && {
        hasDelete,
        deleteBtnId: data[gridRowId]?.toString(),
        onDelete: () => onDelete ? onDelete(data) : undefined,
        isDeleteDisabled
      })}
      {...(hasEdit && {
        hasEdit,
        editBtnId: data[gridRowId]?.toString(),
        onEdit: () => onEdit ? onEdit(data) : undefined,
        isEditDisabled
      })}
    />
  );
};

RowActionButtonsRenderer.defaultProps = {
  hasAuditSummary: false
}

export default RowActionButtonsRenderer;
