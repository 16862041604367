import React, { useState, useEffect, useLayoutEffect } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Input, Popover } from 'antd';
import type { CollapseProps } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { SearchOutlined } from '@ant-design/icons';
import { cloneDeep, isEqual, orderBy, pickBy, uniq, transform, isObject, omit } from 'lodash';
import {
  checkIfAllColumnItemSelected,
  checkifAnyObjectPropertyIsTruthy
} from '../../../helpers/commonHelper';
import { FILTER_CATEGORY_MAX_LIMIT } from '../../../constants/commonConstants';
import textConstants from '../../../constants/textConstants';
import {
  FilterButtonWrapper,
  FilterCategoryContainer,
  FilterCategoryItemWrapper,
  FilterCategoryListWrapper,
  FilterCategoryTitle,
  FilterCategorySearchWrapper,
  FilterPopoverWrapper
} from './style';

interface FilterPopoverProps {
  appliedFilters: any
  categoryNameList: any[]
  children?: ReactNode
  handlePopoverVisibility: any
  isOpen: boolean
  initialTableData: any[]
  onFilterApply: any
  onFilterReset: any
  selectedColumnItemCount: any
  tableData: any[]
}

const FilterPopover: FC<FilterPopoverProps> = ({
  appliedFilters,
  categoryNameList,
  children,
  isOpen,
  initialTableData,
  handlePopoverVisibility,
  onFilterApply,
  onFilterReset,
  selectedColumnItemCount,
  tableData
}) => {
  const [categorySelectAllConfig, setCategorySelectAllConfig] = useState<any>({});
  const [filterData, setFilterData] = useState<any>({});
  const [tableRowData, setTableRowData] = useState<any[]>([]);
  const [initialFilterData, setInitialFilterData] = useState<any>({});
  const [selectedColumnItemConfig, setSelectedColumnItemConfig] = useState<any>({});

  const handleFilterColumnSelectedItems = (
    filterColObj: any,
    categoryHeading: string,
    isItemSelected: boolean
  ): any => {
    if (!isItemSelected) {
      if (filterColObj && filterColObj[categoryHeading] > 1) {
        filterColObj[categoryHeading] = filterColObj[categoryHeading] - 1;
      } else {
        filterColObj = omit(filterColObj, categoryHeading);
      }
    } else {
      filterColObj[categoryHeading] = filterColObj?.[categoryHeading]
        ? filterColObj[categoryHeading] + 1
        : 1;
    }

    return filterColObj;
  };

  const omitFilterItemsProperty = (filterObj: any): any => {
    return transform(filterObj, function (result: any, value: any, key: string) {
      result[key] = isObject(value) ? omit(value, 'filterItems') : value;
    });
  };

  const getTableDataOnFilterChange = (
    filterDataConfig: any,
    selectedColumnItemConfig: any
  ): any => {
    let filteredTableData: any = [];

    filteredTableData = initialTableData.filter((item) => {
      const checkFilterColumns = Object.keys(selectedColumnItemConfig).map((value: any) => {
        return (
          item[value].toString() in filterDataConfig[value].items &&
          filterDataConfig[value]?.items[item[value]] === true
        );
      });
      return !checkFilterColumns.includes(false);
    });

    categoryNameList.forEach((item: any) => {
      filterDataConfig = {
        ...filterDataConfig,
        [item.field]: {
          ...filterDataConfig[item.field],
          label: item.headerName,
          items:
            item.field in selectedColumnItemConfig
              ? filterDataConfig[item.field].items
              : getCategoryItem(item.field, filteredTableData, filterDataConfig)
        }
      };
    });

    if (!checkifAnyObjectPropertyIsTruthy(filterDataConfig)) {
      filterDataConfig = initialFilterData;
      filteredTableData = initialTableData;
    }

    return {
      tableData: filteredTableData,
      updatedFilterData: filterDataConfig
    };
  };

  /* Method to handle category select/un-select all toggle */
  const onCategorySelectAllToggle = (categoryName: string, isChecked: boolean): void => {
    let selectedItemConfigObj = cloneDeep(selectedColumnItemConfig);
    const clonedFilteredData = cloneDeep(filterData);

    if (isChecked) {
      const selectedItemCount = Object.keys(clonedFilteredData[categoryName].items).length;
      selectedItemConfigObj = {
        ...selectedItemConfigObj,
        [categoryName]: selectedItemCount
      };
    } else {
      selectedItemConfigObj = omit(selectedItemConfigObj, categoryName);
    }

    Object.keys(clonedFilteredData[categoryName].items).forEach(
      (v: any) => (clonedFilteredData[categoryName].items[v] = isChecked)
    );

    if (clonedFilteredData[categoryName].filterItems) {
      Object.keys(clonedFilteredData[categoryName].filterItems).forEach(
        (v: any) => (clonedFilteredData[categoryName].filterItems[v] = isChecked)
      );
    }

    const data = getTableDataOnFilterChange(clonedFilteredData, selectedItemConfigObj);

    setSelectedColumnItemConfig(selectedItemConfigObj);
    setFilterData(data.updatedFilterData);
    setTableRowData(data.tableData);
    setCategorySelectAllConfig({
      ...categorySelectAllConfig,
      [categoryName]: isChecked
    });
  };

  /* Method to handle category select/un-select on single selection change */
  const onCategoryItemChangeSelection = (
    evt: any,
    categoryName: string,
    fieldName: string
  ): void => {
    const clonedFilteredData = cloneDeep(filterData);
    let selectedColDataObj = cloneDeep(selectedColumnItemConfig);

    selectedColDataObj = handleFilterColumnSelectedItems(
      selectedColDataObj,
      categoryName,
      evt.target.checked
    );

    clonedFilteredData[categoryName].items[fieldName] = evt.target.checked;

    if (clonedFilteredData[categoryName].filterItems) {
      clonedFilteredData[categoryName].filterItems[fieldName] = evt.target.checked;
    }

    const data = getTableDataOnFilterChange(clonedFilteredData, selectedColDataObj);
    const allItemTruthy = checkIfAllColumnItemSelected(clonedFilteredData, categoryName);

    setTableRowData(data.tableData);
    setCategorySelectAllConfig({
      ...categorySelectAllConfig,
      [categoryName]: allItemTruthy
    });
    setSelectedColumnItemConfig(selectedColDataObj);
    setFilterData(data.updatedFilterData);
  };

  const onCategorySearchChange = (evt: any): void => {
    const clonedFilteredData = cloneDeep(filterData);
    let clonedInitialFilteredData = cloneDeep(initialFilterData);
    clonedFilteredData[evt.target.name].filterItems = pickBy(
      clonedFilteredData[evt.target.name].items,
      function (value, itemKey) {
        return itemKey.toLowerCase().includes(evt.target.value.toLowerCase());
      }
    );

    clonedInitialFilteredData = {
      ...clonedInitialFilteredData,
      [evt.target.name]: {
        ...clonedInitialFilteredData[evt.target.name],
        filterItems: clonedFilteredData[evt.target.name].filterItems
      }
    };

    setFilterData(clonedFilteredData);
    setInitialFilterData(clonedInitialFilteredData);
  };

  /* Method to reset applied filters */
  const onReset = (): void => {
    // let filterTableDataObj = {};

    // categoryNameList.forEach((item: any) => {
    //   filterTableDataObj = {
    //     ...filterTableDataObj,
    //     [item.field]: {
    //       label: item.headerName,
    //       items: getCategoryItem(item.field, tableData)
    //     }
    //   };
    // });

    // setSelectedColumnItemConfig({});
    // setFilterData(filterTableDataObj);
    // setCategorySelectAllConfig({});
    // setTableRowData(initialTableData);
    onFilterApply(initialTableData, {});
    onFilterReset();
  };

  /* Method to apply selected filters */
  const onApply = (): void => {
    // To remove temporary filterItems property from filterData
    const appliedFilterData = omitFilterItemsProperty(filterData);

    // To Check if any change in filters
    const isFilterUpdated = isEqual(appliedFilterData, initialFilterData);

    // setSelectedColumnItemConfig({});
    onFilterApply(tableRowData, isFilterUpdated ? {} : appliedFilterData, selectedColumnItemConfig);
    handlePopoverVisibility(false);
  };

  const getCategoryItem = (categoryName: string, tableDataArr: any, filterDataObj?: any): any => {
    const categoryItemData = uniq(
      tableDataArr?.filter((row: any) => categoryName in row && row[categoryName])
    );

    const sortedItemData = orderBy(
      categoryItemData,
      [(column: any) => column[categoryName].toString().toLowerCase()],
      'asc'
    ).map((row: any) => {
      return {
        [row[categoryName]]: filterDataObj?.[categoryName]?.items[row[categoryName]] || false
      };
    });
    const itemObject = Object.assign({}, ...sortedItemData);

    return itemObject;
  };

  useEffect(() => {
    setTableRowData(tableData);
  }, [tableData]);

  useEffect(() => {
    if (isOpen) {
      let filterTableDataObj: any = {};
      if (Object.keys(appliedFilters).length > 0) {
        setFilterData(appliedFilters);
      } else if (Object.keys(selectedColumnItemConfig).length < 1) {
        let selectAllColumnStatusObj = {};

        categoryNameList.forEach((item: any) => {
          filterTableDataObj = {
            ...filterTableDataObj,
            [item.field]: {
              label: item.headerName,
              items: getCategoryItem(item.field, tableData)
            }
          };
          selectAllColumnStatusObj = {
            ...selectAllColumnStatusObj,
            [item.field]: false
          };
        });

        setCategorySelectAllConfig(selectAllColumnStatusObj);
        setFilterData(filterTableDataObj);
        setInitialFilterData(filterTableDataObj);
      }
    }
  }, [appliedFilters, isOpen, tableData]);

  useLayoutEffect(() => {
    if (selectedColumnItemCount && Object.keys(selectedColumnItemCount).length > 0 && !isOpen) {
      const data = getTableDataOnFilterChange(appliedFilters, selectedColumnItemCount);
      setTableRowData(data.tableData);
      setSelectedColumnItemConfig(selectedColumnItemCount);
      setFilterData(data.updatedFilterData);
      onFilterApply(data.tableData, data.updatedFilterData, selectedColumnItemCount);
    } else {
      setSelectedColumnItemConfig(selectedColumnItemCount);
    }
  }, [selectedColumnItemCount]);

  useEffect(() => {
    if (Object.keys(appliedFilters).length > 0 || Object.keys(filterData).length > 0) {
      const clonedFilteredData = cloneDeep(filterData);

      const selectedColDataObj = cloneDeep(selectedColumnItemConfig);
      const data = getTableDataOnFilterChange(clonedFilteredData, selectedColDataObj);

      setSelectedColumnItemConfig(selectedColDataObj);
      setFilterData(data.updatedFilterData);
      setTableRowData(data.tableData);

      if (Object.keys(appliedFilters).length > 0) {
        onFilterApply(data.tableData, data.updatedFilterData, selectedColDataObj);
      }
    }
  }, [initialTableData]);

  useEffect(() => {
    if (!isOpen) {
      const initFilterData = omitFilterItemsProperty(initialFilterData);
      setInitialFilterData(initFilterData);
    }
  }, [isOpen]);

  const items: CollapseProps['items'] = Object.entries(filterData)
    .filter(
      ([fieldKey, value]: any) =>
        fieldKey !== 'application_nm' || (value?.items && Object.keys(value.items).length > 1)
    )
    .map(([fieldKey, value]: any) => ({
      key: fieldKey,
      label: <FilterCategoryTitle data-testid={fieldKey}>{value.label}</FilterCategoryTitle>,
      children: (
        <FilterCategoryListWrapper>
          <FilterCategorySearchWrapper>
            <Input
              name={fieldKey}
              placeholder={`Search ${String(value?.label)}`}
              addonBefore={<SearchOutlined />}
              onChange={(evt) => onCategorySearchChange(evt)}
              allowClear
            />
            <Button
              type="link"
              data-testid={`select${value.label}AllBtn`}
              onClick={() =>
                onCategorySelectAllToggle(fieldKey, !categorySelectAllConfig[fieldKey])
              }
            >
              {categorySelectAllConfig[fieldKey] === true
                ? textConstants.FILTER_DATA_DESELECT_ALL_LABEL
                : textConstants.FILTER_DATA_SELECT_ALL_LABEL}
            </Button>
          </FilterCategorySearchWrapper>
          <FilterCategoryItemWrapper>
            {Object.entries(value.filterItems || value.items).filter((item: any, index: number) => index < FILTER_CATEGORY_MAX_LIMIT).map(([itemKey, value]: any) => {
              return (
                <Checkbox
                  key={itemKey}
                  data-testid={itemKey}
                  id={itemKey}
                  checked={value}
                  onChange={(evt: CheckboxChangeEvent) => onCategoryItemChangeSelection(evt, fieldKey, itemKey)}
                >
                  {itemKey}
                </Checkbox>
              );
            })}
          </FilterCategoryItemWrapper>
        </FilterCategoryListWrapper>
      )
    }));

  return (
    <Popover
      arrow={false}
      open={isOpen}
      onOpenChange={handlePopoverVisibility}
      placement="bottomRight"
      trigger="click"
      content={
        <FilterPopoverWrapper data-testid="filterContentWrapper">
          <h1>{textConstants.FILTER_DATA_TITLE}</h1>
          <FilterCategoryContainer items={items} destroyInactivePanel />
          <FilterButtonWrapper className="text-right">
            <Button
              type="text"
              className="mr-10 btn-regular"
              onClick={onReset}
              data-testid="resetFilterBtn"
            >
              {textConstants.RESET_BUTTON_LABEL}
            </Button>
            <Button
              className="btn-regular"
              type="primary"
              onClick={onApply}
              data-testid="applyFilterBtn"
            >
              {textConstants.APPLY_BUTTON_LABEL}
            </Button>
          </FilterButtonWrapper>
        </FilterPopoverWrapper>
      }
      destroyTooltipOnHide
      rootClassName="filter-popover"
    >
      {children}
    </Popover>
  );
};

FilterPopover.propTypes = {
  appliedFilters: PropTypes.object.isRequired,
  categoryNameList: PropTypes.array.isRequired,
  children: PropTypes.any,
  isOpen: PropTypes.bool.isRequired,
  initialTableData: PropTypes.array.isRequired,
  handlePopoverVisibility: PropTypes.func.isRequired,
  onFilterApply: PropTypes.func.isRequired,
  onFilterReset: PropTypes.func.isRequired,
  selectedColumnItemCount: PropTypes.object.isRequired,
  tableData: PropTypes.array.isRequired
};

export default FilterPopover;
