/**
 * Contains all api endpoints used in UI
 */
import {
  ADMIN_DATA_API_URL,
  DOWNLOAD_DATA_API_URL,
  EXCEPTION_DATA_API_URL,
  LOG_DATA_API_URL,
  METRIC_DATA_API_URL,
  REPLAY_DATA_API_URL,
  USER_DATA_API_URL
} from '../services/setEndpointURL';

const apiEndpointConstants = {
  // User Data Endpoints
  USER_AUTHENITCATE: `${USER_DATA_API_URL}users/authenticate/`,

  // Admin Data Endpoints
  GET_APPLICATION_CONFIG_DETAILS: `${ADMIN_DATA_API_URL}application/config`,
  GET_EXCEPTION_CATEGORY: `${USER_DATA_API_URL}users/fetchExceptionCategoryByUserRole/`,
  GET_EXCEPTION_SEVERITY: `${USER_DATA_API_URL}users/fetchExceptionSeverityByUserRole/`,
  GET_EXCEPTION_TYPE: `${USER_DATA_API_URL}users/fetchExceptionTypeByUserRole/`,
  GET_REPLAY_CONFIG_LIST: `${ADMIN_DATA_API_URL}application/config/replay/`,
  GET_REPORT_CONFIG_LIST: `${ADMIN_DATA_API_URL}reportconfig/`,
  SAVE_UPDATE_REPORT_CONFIG: `${ADMIN_DATA_API_URL}reportconfig/`,
  DELETE_REPORT_CONFIG: `${ADMIN_DATA_API_URL}reportconfig/`,
  SAVE_UPDATE_REPLAY_CONFIG: `${ADMIN_DATA_API_URL}application/config/replay/`,
  DELETE_REPLAY_CONFIG: `${ADMIN_DATA_API_URL}application/config/replay/`,
  EXPORT_COST_ANALYSIS_REPORT: `${ADMIN_DATA_API_URL}monthlyCountDataToExcel`,

  GET_SOLMAN_STAGING_CONFIG_LIST: `${ADMIN_DATA_API_URL}application/solman/data/`,
  GET_SCHEDULE_CONFIG_LIST: `${ADMIN_DATA_API_URL}scheduleconfig/`,
  GET_APPLICATION_SECTOR_LIST: `${ADMIN_DATA_API_URL}application/sector/`,
  GET_COLUMN_ALERT_CONFIG_LIST: `${ADMIN_DATA_API_URL}application/colalert/`,
  COLUMN_ALERT_CONFIG: `${ADMIN_DATA_API_URL}application/colalert/`,
  GET_EXCEPTION_CONFIG_LIST: `${ADMIN_DATA_API_URL}application/config/exception/`,
  SAVE_UPDATE_EXCEPTION_CONFIG: `${ADMIN_DATA_API_URL}application/config/exception/`,
  DELETE_EXCEPTION_CONFIG: `${ADMIN_DATA_API_URL}application/config/exception/`,
  TABLE_META_CONFIG: `${ADMIN_DATA_API_URL}application/meta/config/`,
  SAVE_UPDATE_TABLE_META_CONFIG: `${ADMIN_DATA_API_URL}application/meta/config/`,
  GET_LOG_REPORT_CONFIG_LIST: `${ADMIN_DATA_API_URL}log/report/config/`,
  SAVE_UPDATE_LOG_REPORT_CONFIG: `${ADMIN_DATA_API_URL}log/report/config/`,
  DELETE_LOG_REPORT_CONFIG: `${ADMIN_DATA_API_URL}log/report/config/`,
  GET_EXCEPTION_REPORT_CONFIG_LIST: `${ADMIN_DATA_API_URL}exception/report/config/`,
  SAVE_UPDATE_EXCEPTION_REPORT_CONFIG: `${ADMIN_DATA_API_URL}exception/report/config/`,
  DELETE_EXCEPTION_REPORT_CONFIG: `${ADMIN_DATA_API_URL}exception/report/config/`,
  APPLICATION_EXCEPTION_CATEGORY_CONFIG: `${ADMIN_DATA_API_URL}application/category/`,
  APPLICATION_EXCEPTION_SEVERITY_CONFIG: `${ADMIN_DATA_API_URL}application/severity/`,
  APPLICATION_EXCEPTION_TYPE_CONFIG: `${ADMIN_DATA_API_URL}application/type/`,

  // Admin Config Audit
  GET_ADMIN_CONFIG_AUDIT_HISTORY: `${ADMIN_DATA_API_URL}config/audit/`,

  // Blob Metrics Endpoints
  GET_LOG_SUMMARY_BLOB_METRIC_DATA: `${METRIC_DATA_API_URL}log/blob/metrics/`,
  GET_EXCEPTION_SUMMARY_BLOB_METRIC_DATA: `${METRIC_DATA_API_URL}exception/blob/metrics/`,
  GET_LOG_DETAILS_BLOB_METRIC_DATA: `${METRIC_DATA_API_URL}log/blob/details/`,
  GET_LOG_BLOB_METRIC_ITEM_DETAILS: `${METRIC_DATA_API_URL}log/blob/item/`,
  GET_EXCEPTION_DETAILS_BLOB_METRIC_DATA: `${METRIC_DATA_API_URL}exception/blob/details/`,
  GET_EXCEPTION_BLOB_METRIC_ITEM_DETAILS: `${METRIC_DATA_API_URL}exception/blob/item/`,

  // Dashboard Endpoints
  GET_DASHBOARD_LOG_METRICS: `${METRIC_DATA_API_URL}metrics/dashboard-metrics/applications/logs/`,
  GET_DASHBOARD_EXCEPTION_METRICS: `${METRIC_DATA_API_URL}metrics/dashboard-metrics/applications/exceptions/`,
  GET_DASHBOARD_LOG_COMPONENT_SERVICE_METRICS: `${METRIC_DATA_API_URL}metrics/dashboard-metrics/serv-comp/logs/`,
  GET_DASHBOARD_EXCEPTION_COMPONENT_SERVICE_METRICS: `${METRIC_DATA_API_URL}metrics/dashboard-metrics/serv-comp/exceptions/`,
  GET_DASHBOARD_REPLAY_METRICS: `${METRIC_DATA_API_URL}metrics/dashboard-metrics/search/replayStatistics`,

  // Exception Data Endpoints
  GET_EXCEPTION_DETAILS: `${EXCEPTION_DATA_API_URL}exception/item/`,
  GET_EXCEPTION_COUNT: `${EXCEPTION_DATA_API_URL}exception/count/`,
  GET_EXCEPTION_SEARCH_DATA: `${EXCEPTION_DATA_API_URL}exception/search/`,
  EXCEPTION_DATA: `${EXCEPTION_DATA_API_URL}exception/`,

  // Log Data Endpoints
  GET_LOG_DETAILS: `${LOG_DATA_API_URL}log/item/`,
  GET_LOG_COUNT: `${LOG_DATA_API_URL}log/count/`,
  GET_LOG_SEARCH_DATA: `${LOG_DATA_API_URL}log/search/`,

  // Download Data Endpoints
  LOG_EXCEPTION_EXPORT_VOLUME_METRICS_REPORT: `${DOWNLOAD_DATA_API_URL}/download/volume/metrics/`,
  LOGS_SELECTED_EXPORT_TO_EXCEL: `${DOWNLOAD_DATA_API_URL}log/download/selected`,
  UPLOAD_EXCEPTION_CONFIG: `${DOWNLOAD_DATA_API_URL}/exception/config/upload/`,
  DOWNLOAD_EXCEPTION_CONFIG: `${DOWNLOAD_DATA_API_URL}/exception/download/config/`,

  // Replay Data Endpoints
  BULK_REPLAY_DATA_UPLOAD: `${REPLAY_DATA_API_URL}replay/file/`,
  GET_FILE_REPLAY_DASHBOARD_DATA: `${REPLAY_DATA_API_URL}replay/file/user/`,
  GET_REPLAY_FILE_DETAILS: `${REPLAY_DATA_API_URL}replay/file`,
  EXCEPTION_REPLAY: `${REPLAY_DATA_API_URL}replay/`,
  EXCEPTION_REPLAY_STATUS: `${REPLAY_DATA_API_URL}replay/status/`,
  REPLAY_AUDIT: `${REPLAY_DATA_API_URL}replay/audit/`,

  // User Data Endpoints
  GET_USER_LIST: `${ADMIN_DATA_API_URL}users/`,
  SAVE_UPDATE_USER_LIST: `${ADMIN_DATA_API_URL}users/`,
  DELETE_USER_LIST: `${ADMIN_DATA_API_URL}users/`,
  GET_NON_ADMIN_APPLICATION_CONFIG_DETAILS: `${USER_DATA_API_URL}users/fetchApplicationConfig/`,
  GET_MIRROR_USER_ACCESS: `${ADMIN_DATA_API_URL}users/app/mirrorUser`,

  // Group Data Endpoints
  GET_GROUP_LIST: `${ADMIN_DATA_API_URL}group/`,
  GET_GROUP_ACCESS_LIST: `${ADMIN_DATA_API_URL}group/access/`,
  GROUP_ACCESS_CONFIG: `${ADMIN_DATA_API_URL}group/access/`,

  // Saved Search Criteria EndPoints
  SAVED_SEARCH: `${USER_DATA_API_URL}search-criteria/`

};

export default apiEndpointConstants;
