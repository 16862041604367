import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { FloatingTagWrapper, TagWrapper } from './style';

interface tagProps {
  isClosable?: boolean
  text?: string | React.ReactNode
  onClose?: any
  additionalInfo?: string
  theme?: string
}

const Tags: FC<tagProps> = ({ isClosable, text, onClose, additionalInfo, theme }) => {
  return (
    <TagWrapper className={theme}
      closable={isClosable}
      onClose={onClose}
      data-title={additionalInfo}
    >
      <FloatingTagWrapper>
        {
          additionalInfo &&
            <span className={'float-label'}>
              {additionalInfo}
            </span>
        }
        <div className={'tag-text-wrapper'}>
          {text}
        </div>
      </FloatingTagWrapper>
    </TagWrapper>
  );
};

Tags.propTypes = {
  isClosable: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  onClose: PropTypes.func,
  additionalInfo: PropTypes.string,
  theme: PropTypes.string
};

export default Tags;
