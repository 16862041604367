import React from 'react';
import { type FC } from 'react';
import { useHistory } from 'react-router-dom';
import { AdminMainBreadCrumbWrapper, AdminMainLayoutWrapper } from '../../../assets/styles/commonStyled';
import ConfigurationListTable from '../../../components/AdditionalComponents/ConfigurationListTable';
import { USER_LIST_COLUMNS } from '../../../constants/TableColumns/AccessManagement/UserListColumns';
import { getUserList, deleteUserList } from '../../../redux/actions/AccessManagement/UserAccess/userAccessListActions';
import textConstants from '../../../constants/textConstants';
import routeConstants from '../../../constants/routeConstants';

const UserAccessList: FC = () => {
  const history = useHistory();

  const showDetailPageHandler = (rowData: any, isEditMode: boolean = true, isAdd: boolean = false): void => {
    history.push({
      pathname: routeConstants.ADMIN_USER_ACCESS_DETAIL,
      state: {
        isAdd,
        isEditable: isEditMode,
        data: rowData
      }
    });
  }

  return (
    <AdminMainLayoutWrapper data-testid="userList">
      <AdminMainBreadCrumbWrapper>
        {textConstants.USER_LIST_PAGE_TITLE}
      </AdminMainBreadCrumbWrapper>
      <ConfigurationListTable
        columnDefs={USER_LIST_COLUMNS}
        getConfigList={getUserList}
        onConfigDelete={deleteUserList}
        gridName={textConstants.USER_GRID_TITLE}
        filterBy={{
          fieldName: 'status',
          fieldLabel: 'Status'
        }}
        onCreate={() => showDetailPageHandler(null, true, true)}
        onEdit={showDetailPageHandler}
        onRowDoubleClicked={(params: { data: any }) => showDetailPageHandler(params.data, false)}
        gridRowId='userID'
        listName='userConfig'
        hasAuditSummary
        hasRowAction
        hasCreate
      />
    </AdminMainLayoutWrapper>
  );
};

export default UserAccessList;
