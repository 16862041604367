import React, { useState } from 'react';
import type { FC } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AdminMainLayoutWrapper } from '../../../../assets/styles/commonStyled';
import textConstants from '../../../../constants/textConstants';
import ReplayConfigSummary from '../../../../components/AdditionalComponents/DetailSummaryPage/Admin/ReplayConfig/ReplayConfigSummary';
import { saveReplayConfig, deleteReplayConfig } from '../../../../redux/actions/Admin/replayConfigActions';
import { ConfigDetailWrapper } from '../../../../components/AdditionalComponents/DetailSummaryPage/Admin/style';
import ConfigDetailCardHeader from '../../../../components/AdditionalComponents/ConfigDetailCardHeader';
import ReplayConfigForm from '../../../../components/AdditionalComponents/UpsertForms/Admin/ReplayConfig';
import AdminDetailBreadCrumb from '../../../../components/common/BreadCrumb/AdminDetailBreadCrumb';
import { getEditDataChanges } from '../../../../helpers/commonHelper';

const ReplayConfigDetail: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { data, isEditable, isFromApplicationConfig } = location?.state as { isEditable?: boolean, data: any, isFromApplicationConfig?: boolean } ?? {};

  const [isConfigDetailInEditMode, setConfigDetailInEditMode] = useState(isEditable);
  const [configData, setConfigData] = useState(data);

  const onFormSaveCancelHandler = (): void => {
    if (isEditable) {
      history.goBack();
    } else {
      setConfigDetailInEditMode(false);
    }
  }

  const onReplayConfigSaveHandler = (formData: any): void => {
    const updatedPayload = getEditDataChanges(configData, formData, 'replay_host_config');

    if (updatedPayload) {
      dispatch(saveReplayConfig(updatedPayload, onFormSaveCancelHandler));
      setConfigData(formData);
    } else {
      onFormSaveCancelHandler();
    }
  }

  const onReplayConfigDeleteHandler = (): void => {
    dispatch(deleteReplayConfig([data], history.goBack));
  }

  return (
    <AdminMainLayoutWrapper data-testid="replayConfigDetail">
      <AdminDetailBreadCrumb
        isFromApplicationConfig={isFromApplicationConfig}
        mainPageText={textConstants.REPLAY_CONFIG_PAGE_TITLE}
        currentPageText={data?.applicationName}
      />
      <ConfigDetailWrapper>
        <ConfigDetailCardHeader
          onDelete={onReplayConfigDeleteHandler}
          onEdit={() => setConfigDetailInEditMode(true)}
          isEditEnabled={!isConfigDetailInEditMode}
          title={textConstants.REPLAY_CONFIG_DETAIL_PAGE_TITLE}
          configName={textConstants.REPLAY_CONFIG_PAGE_TITLE}
        />
        {isConfigDetailInEditMode && (
          <ReplayConfigForm
            data={configData}
            onSave={onReplayConfigSaveHandler}
            onCancel={onFormSaveCancelHandler}
          />
        )}
        {!isConfigDetailInEditMode && (
          <ReplayConfigSummary
            data={configData}
          />
        )}
      </ConfigDetailWrapper>
    </AdminMainLayoutWrapper>
  );
};

export default ReplayConfigDetail;
