import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Drawer } from 'antd';
import { CONFIGURATION_AUDIT_HISTORY_COLUMN_CONFIG } from '../../../../constants/TableColumns/Admin/ConfigurationAuditHistory';
import ConfigurationListTable from '../../ConfigurationListTable';
import Loader from '../../../common/Loader';
import { commonApiRequestHandler } from '../../../../services/CommonServiceHandler';
import { API_SUCCESS_STATUS_CODE } from '../../../../constants/commonConstants';
import apiURL from '../../../../constants/apiEndpointConstants';

interface ConfigAuditHistoryDrawerProps {
  configName: string
  configId: string
  configTableName: string
  onCancel: () => void
}

const ConfigAuditHistoryDrawer: FC<ConfigAuditHistoryDrawerProps> = ({
  configName,
  configId,
  configTableName,
  onCancel
}) => {
  const [configAuditHistoryData, setConfigAuditHistoryData] = useState<any>([]);
  const [isConfigAuditDrawerOpen, setConfigAuditDrawerState] = useState<boolean>(false);
  const [isLoading, setLoadingStatus] = useState<boolean>(true);

  const onConfigAuditHistoryDrawerClose = (): void => {
    setConfigAuditDrawerState(false);
    onCancel();
  }

  const fetchConfigAuditHistory = async () => {
    try {
      const apiRequestConfig = {
        method: 'GET',
        url: `${apiURL.GET_ADMIN_CONFIG_AUDIT_HISTORY}?id=${configId}&tableName=${configTableName}`
      };
      const auditHistoryResp = await commonApiRequestHandler(apiRequestConfig);
        if (auditHistoryResp.status === API_SUCCESS_STATUS_CODE) {
          setConfigAuditHistoryData(auditHistoryResp.data);
        } else {
          setConfigAuditHistoryData([]);
        }
        setConfigAuditDrawerState(true);
        setLoadingStatus(false);
      } catch (err) {
        setLoadingStatus(false);
        setConfigAuditDrawerState(true);
      }
  }

  useEffect(() => {
    if (configId && configName) {
      fetchConfigAuditHistory();
    }
  }, [configId, configName]);

  return (
    <Drawer
      placement="right"
      onClose={onConfigAuditHistoryDrawerClose}
      getContainer={false}
      width="100%"
      data-testid='configAuditHistory'
      open={isConfigAuditDrawerOpen}
      rootClassName='add-edit-config-drawer config-audit-history-drawer'
      title={`${configName} Audit History`}
    >
      {!isLoading && (
        <ConfigurationListTable
          rowData={configAuditHistoryData}
          columnDefs={CONFIGURATION_AUDIT_HISTORY_COLUMN_CONFIG}
          getConfigList={null}
          listName="auditHistory"
          hasFilter={false}
        />
      )}
      {isLoading && (
        <Loader isOverlayLoader={false} size='medium' />
      )}
    </Drawer>
  )
}

ConfigAuditHistoryDrawer.propTypes = {
  configId: PropTypes.string.isRequired,
  configName: PropTypes.string.isRequired,
  configTableName: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired
}

export default ConfigAuditHistoryDrawer;
